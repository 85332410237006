import { Slider } from "primereact/slider";

export default function SliderInput({
  id,
  label,
  placeholder,
  prefix,
  onSliderChangeValue,
  value,
}) {
  return (
    <div key={id} id={`${id}-input`} className="box-border w-full">
      <label htmlFor="stacked-buttons" className="font-bold block mb-4">
        {label}
      </label>
      <div className="flex align-items-center gap-3">
        <Slider
          onChange={(e) => onSliderChangeValue(e.value)}
          placeholder={placeholder}
          prefix={prefix}
          value={value || 0}
          style={{ flex: 1 }}
        />
        <span>{value || 0}</span>
      </div>
    </div>
  );
}
