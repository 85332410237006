import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  en: {
    title: "Profitability Calculator",
    labels: {
      sessionNumber: "Number of cars",
      chargingAmountPeriod: "Expected charging amount period",
      chargingAmount: "Expected charging amount per year (kWh)",
      chargingAmountPerDay: "Expected charging amount per day (kWh)",
      procurmentElectricityPrice: "Procurement electricity price (per kWh)",
      perYear: "per year",
      perDay: "per day",
      soldElectricityPrice: "Electricity price sold to customer (per kWh)",
      stationUsesPV: "The station uses PV supply",
      pvSupply: "PV (photovoltaic) supply (%)",
    },
    results: {
      revenuePerYear: "Annual revenue (after taxes and electricity costs)",
      incomePerYear: "Profit (after fees)",
      refundPVPerYear: "PV refund (annual)",
      revenuePVPerYear:
        "Total annual revenue (including PV, after fees and maintenance)",
    },

    tooltips: {
      soldElectricityPrice: "The electricity price paid by the end customer.",
      revenuePerYear:
        "The expected revenue per year after expenses deduction (i.e. tax and electricity bill).",
      incomePerYear:
        "The expected revenue per year after administration fees and station maintenance costs.",
      pvRevenue:
        "The expected revenue per year including the PV refund after administration fees and station mintanence costs.",
      pvRefund: "The expected PV refund per year.",
    },
    calculator: "Calculator",
    contactUs: "Contact us",
    pointers: {
      next: "Next",
      back: "Back",
      submit: "Submit",
    },
    contactSubmissionTitle: "Thank you for contacting us",
    contactSubmissionSubtitle: "We will get back to you soon",
    contactFormLabels: {
      username: "Name",
      companyName: "Company Name",
      email: "Email",
      city: "City",
      phone: "Phone number",
    },
    validationErrors: {
      required: "*Required field",
      invalidFormat: "*Falshes Format",
    },
  },

  de: {
    title: "Rentabilitätsrechner",
    labels: {
      sessionNumber: "Anzahl der Autos",
      chargingAmountPeriod: "Erwarteter Ladezeitraum",
      chargingAmount: "Erwartete Lademenge pro Jahr (kWh)",
      chargingAmountPerDay: "Erwartete Lademenge pro Tag (kWh)",
      procurmentElectricityPrice: "Stromeinkaufspreis (pro kWh)",
      perYear: "pro Jahr",
      perDay: "pro Tag",
      soldElectricityPrice: "Strompreis Endkunde (pro kWh)",
      stationUsesPV: "Die Station nutzt PV-Versorgung",
      pvSupply: "PV (Photovoltaik)-Versorgung (%)",
    },
    results: {
      revenuePerYear: "Jahresumsatz (nach Steuern und Stromkosten)",
      incomePerYear: "Gewinn (nach Gebühren)",
      refundPVPerYear: "PV-Rückerstattung (jährlich)",
      revenuePVPerYear:
        "Gesamtjahresumsatz (inkl. PV, nach Gebühren und Wartung)",
    },
    tooltips: {
      soldElectricityPrice: "Strompreis, den der Kunde zahlt.",
      revenuePerYear: "Jahreseinnahmen nach Steuern und Stromkosten.",
      incomePerYear: "Jahreseinnahmen nach Gebühren und Wartung.",
      pvRevenue:
        "Jahreseinnahmen inkl. PV-Rückvergütung nach Gebühren und Wartung.",
      pvRefund: "Jährliche PV-Rückvergütung.",
    },
    calculator: "Kalkulator",
    contactUs: "Kontaktiere uns",
    pointers: {
      next: "Nächste",
      back: "Zurück",
      submit: "Absenden",
    },
    contactSubmissionTitle: "Vielen Dank, dass Sie uns kontaktiert haben",
    contactSubmissionSubtitle: "Wir werden uns bald bei Ihnen melden",
    contactFormLabels: {
      username: "Name",
      companyName: "Firmenname",
      email: "E-Mail",
      city: "Stadt",
      phone: "Telefonnummer",
    },
    validationErrors: {
      required: "*Pflichtfeld",
      invalidFormat: "*Falsches Format",
    },
  },
});

export default strings;
