import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "./theme.css";

import React, { useEffect, useRef, useState } from "react";
import { PrimeReactProvider } from "primereact/api";
import reactToWebComponent from "@r2wc/react-to-web-component";

import { StepperPanel } from "primereact/stepperpanel";
import { Stepper } from "primereact/stepper";
import { Card } from "primereact/card";

import Calculator from "./Calculator";
import ContactUs from "./ContactUs";

import strings from "./localization";

let primeReactProviderConfig;

function App({ pricePerkWh = 0.59 }) {
  const stepperRef = useRef(null);
  const [submitted, setSubmitted] = useState(false);

  const locale = (navigator.language || navigator.userLanguage).split("-")[0];

  useEffect(() => {
    strings.setLanguage(locale);
  }, [locale]);

  return (
    <PrimeReactProvider value={primeReactProviderConfig}>
      <section className="min-w-full flex justify-center">
        <Card
          header={
            <h2 style={{ color: "#4b5563" }} className="text-center mb-0">
              {locale === "de"
                ? "Rentabilitätsrechner"
                : "Profitability Calculator"}
            </h2>
          }
          className="min-w-[50vw] mx-auto box-border pt-1"
        >
          <Stepper className="custom-stepper" ref={stepperRef}>
            <StepperPanel header={locale === "de" ? "Rechner" : "Calculator"}>
              <Calculator pricePerkWh={pricePerkWh} stepperRef={stepperRef} />
            </StepperPanel>
            <StepperPanel
              header={locale === "de" ? "Kontaktiere uns" : "Contact us"}
            >
              <ContactUs
                stepperRef={stepperRef}
                submitted={submitted}
                setSubmitted={setSubmitted}
              />
            </StepperPanel>
          </Stepper>
        </Card>
      </section>
    </PrimeReactProvider>
  );
}
const AppAsWebComponent = reactToWebComponent(App, React, {
  props: {
    pricePerkWh: "number", // Declare the prop to accept dynamic values
  },
});
customElements.define("cocharge-profitability-calculator", AppAsWebComponent);

export default App;
