import { CalculationParams } from "./consts";

export const calculateResults = (inputValues, soldElectricityPricePerkWh) => {
  const chargingAmountAnnual = Number(inputValues.chargingAmountAnnual) || 0;
  const pvSupplyPercentage = Number(inputValues.pvSupply) / 100 || 0; // number format example 0.12

  // Get the price of electricity charged to the station
  const procurementElectricityPricePerKwh =
    inputValues.procurementElectricityPricePerKwh ||
    CalculationParams.defaultProcurementPrice;

  // Calculate cocharge fees per kWh (in euro)
  const cochargeFeePerKWh =
    soldElectricityPricePerkWh * CalculationParams.cochargeFee;

  // Get clean income from electricity price (per kWh)
  const revenuePerKWh =
    soldElectricityPricePerkWh -
    procurementElectricityPricePerKwh -
    cochargeFeePerKWh;

  // Get revenue for the provided power consumption (charging amount)
  const annualRevenueBeforeFees = chargingAmountAnnual * revenuePerKWh;

  // Gather all fees to be applied on total power consumption
  const feesPercentage =
    CalculationParams.administartionFees + CalculationParams.maintenanceFees;

  // Deduct fees from total revenue
  const annualRevenueAfterFees = annualRevenueBeforeFees * (1 - feesPercentage);

  // Return of investment is the revenue / investment (simple ROI)
  const roiWithoutPV =
    annualRevenueAfterFees / CalculationParams.costsForPublicStation;

  const resultsWithoutPvSupply = {
    annualRevenueBeforeFees,
    annualRevenueAfterFees,
    roiWithoutPV,
  };

  let resultsWithPvSupply = {};

  if (pvSupplyPercentage !== 0) {
    // Apply the PV refund
    const powerAmountFromPVSupply = chargingAmountAnnual * pvSupplyPercentage;

    // Refund the sold electricity price (cos PV supply does not need bought electricity)
    const refundedChargingPricePerKwh =
      soldElectricityPricePerkWh - cochargeFeePerKWh;

    // Apply the feed-in refund for using PV supply on the total power consumption
    const pvRefundForChargingAmount =
      powerAmountFromPVSupply *
      (refundedChargingPricePerKwh - CalculationParams.pvRefundPercentage);

    const annualRevenueWithPVRefund =
      pvRefundForChargingAmount + annualRevenueAfterFees;

    // Return of investment is the revenue / investment (e.g. simple ROI)
    const roiWithPV =
      annualRevenueWithPVRefund / CalculationParams.costsForPublicStation;

    resultsWithPvSupply["refundPVSupply"] = pvRefundForChargingAmount;
    resultsWithPvSupply["annualRevenueWithPVRefund"] =
      annualRevenueWithPVRefund;

    resultsWithPvSupply["roiWithPV"] = roiWithPV;
  }

  const results = {
    ...resultsWithoutPvSupply,
    ...resultsWithPvSupply,
  };

  return results;
};
